<template>
  <!-- 产品管理 -->
  <div class="page">
    <div class="topDiv">
      <Form inline class="form formMarginBtm20" ref="dataForm" :model="searchForm" :loading="loading">
        <FormItem prop="product_name">
          <span class="label">产品名称：</span>
          <Select class="iviewIptWidth250 marginRight70" v-model="searchForm.product_name" clearable filterable :loading="loading" :remote-method="getProductSelect" @on-clear="getProductSelect">
            <!-- <Select class="iviewIptWidth250 marginRight70" v-model="searchForm.product_name" clearable filterable :loading="loading"> -->
            <Option v-for="(item, index) in productSelect" :value="item.name" :key="index + item.name">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem prop="factory_id">
          <span class="label">生产厂家：</span>
          <Select class="iviewIptWidth250 marginRight70" v-model="searchForm.factory_id" clearable filterable>
            <Option v-for="item in factorySelect" :value="item.factory_id" :key="item.factory_id">{{ item.factory_name }}</Option>
          </Select>
        </FormItem>
        <FormItem prop="supplier_id">
          <span class="label">供应商：</span>
          <Select class="iviewIptWidth250 marginRight70" v-model="searchForm.supplier_id" clearable filterable>
            <Option v-for="item in supplierSelect" :value="item.supplier_id" :key="item.supplier_id">{{ item.supplier_name }}</Option>
          </Select>
        </FormItem>
        <FormItem prop="status_code">
          <span class="label">状态：</span>
          <Select class="iviewIptWidth250" v-model="searchForm.status_code" clearable>
            <Option v-for="item in statusSelect" :value="item.status_code" :key="item.status_code">{{ item.status_str }}</Option>
          </Select>
        </FormItem>
        <FormItem prop="timeArray">
          <span class="label">有效期：</span>
          <DatePicker type="date" class="iviewIptWidth250" placeholder="请选择" @on-change="changeTime($event, 1)" clearable></DatePicker>
          <span class="centeFont">至</span>
          <DatePicker type="date" class="iviewIptWidth250" placeholder="请选择" v-model="searchForm.end_time_end" :options="options" @on-change="changeTime($event, 2)" clearable></DatePicker>
        </FormItem>
        <FormItem class="po-create-number" :label-width="20">
          <span class="pageBtn finger btnSure" @click="searchProductList">查询</span>
        </FormItem>
      </Form>
    </div>
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}({{ item.num }})
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="tabelDiv">
      <Table @on-sort-change="sortChange" :productList="listColumns" :productData="listData" :pages="pages" @change-page="changePage" :isLoad="isLoad" totalText="条记录" :loading="isLoad" :total="total"> </Table>
    </div>
    <!-- 禁用模态框 -->
    <Modal v-model="noStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认禁用？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="turnNo">确定</span>
          <span class="pageBtn finger btnCancle" @click="noStatus = false">取消</span>
        </div>
      </div>
    </Modal>
    <!-- 启用模态框 -->
    <Modal v-model="yesStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认启用？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="turnYes">确定</span>
          <span class="pageBtn finger btnCancle" @click="yesStatus = false">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
import { throttle } from '../../service/utils'
export default {
  name: 'productManage',
  components: {
    Table,
  },
  computed: {
    totleMoney() {
      let totle = 0
      this.listData.forEach((e, i) => {
        totle = totle * 1 + e.money * 1
      })
      return totle
    },
  },
  data() {
    return {
      isLoad: true,
      cityList: [
        {
          value: 'New York',
          label: 'New York',
        },
        {
          value: 'London',
          label: 'London',
        },
      ],
      navFrist: [
        {
          name: '全部',
          num: 0,
          type: 1,
        },
        {
          name: '待审核',
          num: 0,
          type: 2,
        },
        {
          name: '已审核',
          num: 0,
          type: 3,
        },
        {
          name: '审核驳回',
          num: 0,
          type: 4,
        },
      ],
      clickIndex: 1,
      listColumns: [
        {
          title: '序号',
          key: 'indexs',
          align: 'center',
          width: 75,
        },
        {
          title: '产品名称',
          align: 'center',
          resizable: true,
          minWidth: 120,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  style: {
                    /* color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer', */
                    textDecoration: 'underline',
                  },
                  class: 'tableFont content_left',
                  on: {
                    click: () => {
                      this.goDetails(param.row)
                    },
                  },
                },
                param.row.name
              ),
            ])
          },
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 150,
          resizable: true,
        },
        {
          title: '生产厂家',
          align: 'center',
          resizable: true,
          minWidth: 200,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  style: {
                    /* color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer', */
                    textDecoration: 'none',
                  },
                  class: 'content_left',
                  on: {
                    click: () => {
                      // this.goDetails(param.row)
                    },
                  },
                },
                param.row.factory_name
              ),
            ])
          },
        },
        {
          title: '供应商',
          align: 'center',
          resizable: true,
          minWidth: 200,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  style: {
                    /* color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer', */
                    textDecoration: 'none',
                  },
                  class: 'content_left',
                  on: {
                    click: () => {
                      // this.goDetails(param.row)
                    },
                  },
                },
                param.row.supplier_name
              ),
            ])
          },
        },
        {
          title: '有效期',
          key: 'end_time',
          align: 'center',
          sortable: 'custom',
          minWidth: 80,
        },
        {
          title: '账期',
          key: 'payment_days',
          align: 'center',
          minWidth: 80,
          render: (h, param) => {
            return h('div', {}, param.row.payment_days + '天')
          },
        },
        {
          title: '交易类型',
          key: 'deal_type',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '状态',
          minWidth: 80,
          align: 'center',
          render: (h, param) => {
            let str = param.row.status_str
            switch (str) {
              case '新增':
                return h('div', [
                  h(
                    'span',
                    {
                      style: { color: '#525B6D' },
                    },
                    str
                  ),
                ])
              case '修改':
                return h('div', [
                  h(
                    'span',
                    {
                      style: { color: '#525B6D' },
                    },
                    str
                  ),
                ])
              case '有效':
                return h('div', [
                  h(
                    'span',
                    {
                      style: { color: '#1DDE85' },
                    },
                    str
                  ),
                ])
              case '过期':
                return h('div', [
                  h(
                    'span',
                    {
                      style: { color: '#F55A52' },
                    },
                    str
                  ),
                ])
              case '近效期':
                return h('div', [
                  h(
                    'span',
                    {
                      style: { color: '#FF8E48' },
                    },
                    str
                  ),
                ])
              case '禁用':
                return h('div', [
                  h(
                    'span',
                    {
                      style: { color: '#c0392b' },
                    },
                    str
                  ),
                ])
              case '审核驳回':
                return h('div', [
                  h(
                    'span',
                    {
                      style: { color: '#E65A6B' },
                    },
                    str
                  ),
                ])
            }
          },
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          minWidth: 150,
          render: (h, param) => {
            let status = param.row.status_str
            switch (status) {
              case '新增':
                return h('div', [
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.review(param.row)
                        },
                      },
                    },
                    '审核'
                  ),
                ])
              case '修改':
                return h('div', [
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.review(param.row)
                        },
                      },
                    },
                    '审核'
                  ),
                ])
              case '有效':
                return h('div', [
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.editRow(param.row)
                        },
                      },
                    },
                    '编辑'
                  ),
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.deleteAble,
                        marginLeft30: !this.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.noStatus = true
                          this.modelIndex = param.row.review_id
                        },
                      },
                    },
                    '禁用'
                  ),
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.searchAble,
                        marginLeft30: !this.deleteAble,
                      },
                      on: {
                        click: () => {
                          // this.ship(param.row)
                          this.output(param.row)
                        },
                      },
                    },
                    '导出审批表'
                  ),
                ])
              case '过期':
                return h('div', [
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.modifyAble,
                      },
                      style: {
                        marginRight: '20px',
                      },
                      on: {
                        click: () => {
                          this.editRow(param.row)
                        },
                      },
                    },
                    '编辑'
                  ),
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.deleteAble,
                      },
                      on: {
                        click: () => {
                          this.noStatus = true
                          this.modelIndex = param.row.review_id
                        },
                      },
                    },
                    '禁用'
                  ),
                ])
              case '近效期':
                return h('div', [
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.editRow(param.row)
                        },
                      },
                    },
                    '编辑'
                  ),
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.deleteAble,
                        marginLeft30: !this.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.noStatus = true
                          this.modelIndex = param.row.review_id
                        },
                      },
                    },
                    '禁用'
                  ),
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.searchAble,
                        marginLeft30: !this.deleteAble,
                      },
                      on: {
                        click: () => {
                          // this.ship(param.row)
                          this.output(param.row)
                        },
                      },
                    },
                    '导出审批表'
                  ),
                ])
              case '禁用':
                return h('div', [
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.deleteAble,
                      },
                      on: {
                        click: () => {
                          this.yesStatus = true
                          this.modelIndex = param.row.review_id
                        },
                      },
                    },
                    '启用'
                  ),
                ])
              case '审核驳回':
                return h('div', [])
            }
          },
        },
      ],
      listData: [],
      totalList: [],
      statusSelect: [],
      factorySelect: [],
      supplierSelect: [],
      productSelect: [],
      loading: false,
      searchForm: {
        product_name: '',
        factory_id: null,
        supplier_id: null,
        end_time_start: '',
        end_time_end: '',
        status_code: null,
      },
      pageSearch: {},
      total: 28,
      pages: {
        page: 1,
        rows: 10,
      },
      review_status: '' /* 列表审核状态 */,
      noStatus: false /* 禁用模态框状态 */,
      yesStatus: false /* 启用模态框状态 */,
      modelIndex: 0 /* 状态改变的id */,
      options: {},
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
      orderdesc: {},
    }
  },
  methods: {
    sortChange(data) {
      console.log(data)
      this.orderdesc = data
      this.searchForm.sort_str = data.key
      this.searchForm.sort_type = data.order
      console.log(this.searchForm)
      // this.review_status = ''
      console.log(this.searchForm, '可以是')
      this.getProductList(this.pages, this.review_status, this.searchForm)
    },
    // 点击编辑
    editRow(row) {
      this.$router.push({
        name: 'productFrame',
        query: {
          reviewId: row.review_id,
          status: 3,
        },
      })
    },
    // 点击审核
    review(row) {
      this.$router.push({
        name: 'productFrame',
        query: {
          reviewId: row.review_id,
          status: 2,
        },
      })
    },
    ship() {
      this.$router.push('/productFrame')
    },
    goDetails(row) {
      this.$router.push({
        name: 'productFrame',
        query: {
          reviewId: row.review_id,
          status: 1,
        },
      })
    },
    changePage(e) {
      this.pages.page = e
      console.log(this.searchForm)
      this.sortChange(this.orderdesc)
      // this.getProductList(this.pages, this.review_status, this.pageSearch)
    },
    /* 点击tabs事件 */
    clickNav(type, flag) {
      // 参数二 避免缓存页数初始化
      this.clickIndex = type
      if (type == 1) {
        this.review_status = ''
        if (flag != '1') {
          this.pages = { page: 1, rows: 10 }
        }
        this.getProductList(this.pages, this.review_status, this.pageSearch, false, false).then(() => {
          this.total = this.navFrist[0].num
        })
      }
      if (type == 2) {
        this.review_status = '1'
        if (flag != '1') {
          this.pages = { page: 1, rows: 10 }
        }
        this.getProductList(this.pages, this.review_status, this.pageSearch, false, false).then(() => {
          this.total = this.navFrist[1].num
        })
      }
      if (type == 3) {
        this.review_status = '2'
        if (flag != '1') {
          this.pages = { page: 1, rows: 10 }
        }
        this.getProductList(this.pages, this.review_status, this.pageSearch, false, false).then(() => {
          this.total = this.navFrist[2].num
        })
      }
      if (type == 4) {
        this.review_status = '3'
        if (flag != '1') {
          this.pages = { page: 1, rows: 10 }
        }
        this.getProductList(this.pages, this.review_status, this.pageSearch, false, false).then(() => {
          this.total = this.navFrist[3].num
        })
      }
    },
    /* 获取产品列表 */
    async getProductList(pages, review_status, obj, flag1 = true, flag2 = true) {
      let searchItem = {}
      Object.assign(searchItem, pages, { review_status }, obj)
      this.totalList = []
      searchItem.status_code = searchItem.status_code ? searchItem.status_code + '' : ''
      let res = await this.$http.get(this.$api.getProductList, searchItem, true)
      if (!res.data.rows.length) {
        this.pages = { page: 1, rows: 10 }
        Object.assign(searchItem, this.pages)
        this.isLoad = true
        res = await this.$http.get(this.$api.getProductList, searchItem)
        this.isLoad = false
      }
      this.isLoad = false
      for (const item of res.data.rows) {
        this.totalList.push(item)
      }
      // if (flag1) {
      //   this.navFrist[0].num = res.data.total
      // }
      // if (flag2) {
      //   this.navFrist[1].num = res.data.no_review_total
      //   this.navFrist[2].num = res.data.review_pass_total
      //   this.navFrist[3].num = res.data.review_rejected_total
      // }
      this.navFrist[0].num = res.data.all_total
      this.navFrist[1].num = res.data.no_review_total
      this.navFrist[2].num = res.data.review_pass_total
      this.navFrist[3].num = res.data.review_rejected_total
      for (const item of this.totalList) {
        item.end_time = item.end_time ? this.$moment.unix(item.end_time).format('YYYY-MM-DD') : ''
        item.licence_code = item.licence_code || item.record_code /* 注册证号和备案证号选择其一，有注册证号优先注册证 */
      }
      this.listData = this.totalList
      this.listData.forEach((item, index) => {
        // 增加序号
        item.indexs = index + 1 + (this.pages.page - 1) * 10
        // 处理空值到话用/代替
        for (var key in item) {
          if (!item[key]) {
            item[key] = '/'
          }
        }
      })
      this.total = res.data.total
    },
    /* 获取下拉列表 */
    getSelect() {
      this.$http.get(this.$api.getStatusSelect).then(res => {
        if (res.status) {
          this.statusSelect = res.data
          this.statusSelect.forEach(item => {
            item.status_code = item.status_code + ''
          })
        }
      })
      this.$http.get(this.$api.getFactorySelect).then(res => {
        if (res.status) {
          this.factorySelect = res.data
        }
      })
      this.$http.get(this.$api.getSupplierSelect).then(res => {
        if (res.status) {
          this.supplierSelect = res.data
        }
      })
      this.$http.get(this.$api.getProductNameSelect).then(res => {
        if (res.status) {
          this.productSelect = res.data
        }
      })
    },
    /* 获取产品名字下拉 */
    getProductSelect(e) {
      this.$http.get(this.$api.getProductNameSelect, { search_key: e }).then(res => {
        this.productSelect = res.data
      })
    },
    /* 搜索产品 */
    searchProductList() {
      console.log(this.searchForm)
      this.pageSearch = []
      let end_time_start = ''
      let end_time_end = ''
      end_time_start = this.$moment(this.searchForm.end_time_start).format('YYYY-MM-DD')
      end_time_end = this.$moment(this.searchForm.end_time_end).format('YYYY-MM-DD')
      let obj = {}
      this.pages = { page: 1, rows: 10 }
      if (this.searchForm.product_name) {
        this.$set(obj, 'product_name', this.searchForm.product_name)
      }
      if (this.searchForm.factory_id) {
        this.$set(obj, 'factory_id', this.searchForm.factory_id)
      }
      if (this.searchForm.supplier_id) {
        this.$set(obj, 'supplier_id', this.searchForm.supplier_id)
      }
      if (end_time_start !== 'Invalid date') {
        this.$set(obj, 'end_time_start', end_time_start)
      }
      if (end_time_end !== 'Invalid date') {
        this.$set(obj, 'end_time_end', end_time_end)
      }
      if (this.searchForm.status_code || this.searchForm.status_code === 0) {
        this.$set(obj, 'status_code', this.searchForm.status_code + '')
      }
      this.clickIndex = 1
      this.review_status = ''
      console.log(this.searchForm, obj)
      this.getProductList(this.pages, this.review_status, obj)

      this.pageSearch = obj
      this.getProductSelect()
      console.log(this.searchForm)
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.searchForm.end_time_start = e
        that.searchForm.end_time_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.searchForm.end_time_start)
          },
        }
      } else {
        this.searchForm.end_time_end = e
      }
    },
    /* 变为禁用状态 */
    turnNo() {
      let review_id = this.modelIndex
      let status_code = -2
      let obj = { review_id, status_code }
      this.$http.post(this.$api.changeStatus, obj).then(res => {
        if (res.status) {
          this.$Message.success('禁用成功')
          this.getProductList(this.pages, this.review_status, this.pageSearch, false, true)
        } else {
          this.$Message.error('禁用失败')
        }
      })
      this.noStatus = false
    },
    /* 变为启用状态 */
    turnYes() {
      let review_id = this.modelIndex
      let status_code = 2
      let obj = { review_id, status_code }
      this.$http.post(this.$api.changeStatus, obj).then(res => {
        if (res.status) {
          this.$Message.success('启用成功')
          this.getProductList(this.pages, this.review_status, this.pageSearch, false, true)
        } else {
          this.$Message.error('启用失败')
        }
      })
      this.yesStatus = false
    },
    // 导出审批表
    output(row) {
      // this.$Message.warning('功能还在开发中...')
      this.$http.downFile(this.$api.productBusinessReviewExport, { review_id: row.review_id }, true).then(res => {
        let csvData = new Blob([res.data], { type: 'application/x-xlsx' }) // response.data要导出的内容
        let file_name = '审批表' + '.xls'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(csvData, file_name)
        } else {
          var a = document.createElement('a')
          var url = window.URL.createObjectURL(csvData)
          a.href = url
          // eslint-disable-next-line camelcase
          a.download = file_name
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        }
      })
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = !rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    },
  },
  async activated() {
    if (sessionStorage.getItem('updataCache') == 0) {
      this.clickNav(this.clickIndex, '1')
      // let obj = {}
      // // this.sortChange(this.orderdesc)
      // let end_time_start = ''
      // let end_time_end = ''
      // end_time_start = this.$moment(this.searchForm.end_time_start).format('YYYY-MM-DD')
      // end_time_end = this.$moment(this.searchForm.end_time_end).format('YYYY-MM-DD')
      // if (this.searchForm.product_name) {
      //   this.$set(obj, 'product_name', this.searchForm.product_name)
      // }
      // if (this.searchForm.factory_id) {
      //   this.$set(obj, 'factory_id', this.searchForm.factory_id)
      // }
      // if (this.searchForm.supplier_id) {
      //   this.$set(obj, 'supplier_id', this.searchForm.supplier_id)
      // }
      // if (end_time_start !== 'Invalid date') {
      //   this.$set(obj, 'end_time_start', end_time_start)
      // }
      // if (end_time_end !== 'Invalid date') {
      //   this.$set(obj, 'end_time_end', end_time_end)
      // }
      // if (this.searchForm.status_code || this.searchForm.status_code === 0) {
      //   this.$set(obj, 'status_code', this.searchForm.status_code + '')
      // }

      // await this.getProductList(this.pages, this.sreview_status, obj)
      // let srt = Number(this.review_status) ? Number(this.review_status) : 0
      // this.getProductList(this.pages, this.review_status, this.pageSearch, false, false).then(() => {
      //     this.total = this.navFrist[srt].num
      // })
      sessionStorage.setItem('updataCache', '1')
    }
  },
  async created() {
    let flag = this.$route.query.show
    if (flag) {
      await this.clickNav(2)
    } else {
      await this.getProductList(this.pages, this.sreview_status, {})
    }
    this.getSelect()
    this.getRightVisibal()
  },
}
</script>

<style scoped lang="less">
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
      }
      /deep/ .ivu-select-dropdown {
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
      position: relative;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.marginRight70 {
  margin-right: 70px;
}
.marginRight90 {
  margin-right: 90px;
}
.centeFont {
  margin: 0 10px;
}
/deep/ .marginLeft30 {
  margin-left: 15px;
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
/deep/ .ivu-table-wrapper {
  overflow: initial !important;
}
</style>
